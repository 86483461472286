import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { images } from "./images";
import Container from "react-bootstrap/Container";

export const About = () => (
  <>
     

      <div className="About" id="AboutContainer">

      <div className="logo">
        <img src="./images/profiles/Logo.svg" alt="Clay Gorilla corporate regrets this interruption. Please refresh.">
            </img>

        </div>
    <div class="row min-100-vh edgetoedge">


      {/* Removed min-100-vh property to fix single project viewport issue. */}
      <div class="col-12 col-sm-12 col-md-9 col-lg-8 col-xl-9">
        <a class="d-flex flex-column justify-content-between .d-block">
          <div class="AboutDescription">
            Clay Gorilla is a design studio redefining <u class="underline">design partnerships</u> at tech startups. We are medium agnostic{" "}
            <b class="super">1</b> and design from 
            first principles.<b class="super">2</b> 
          </div>
        </a>
      </div>
      <div class="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-3">
        {/* <div class="row h-250 spacer"></div> */}
        <div class="row">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="footnote">
              1. Arriving at the solution domain after extensive thought and research, not starting from it.
            </div>
          </a>
        </div>
        <div class="row">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="footnote">
              2. Questioning all perceptions of why something should be the way it is and building from fundamental truths.
            </div>
          </a>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-9 col-lg-8 col-xl-9">
        <a class="d-flex flex-column justify-content-between .d-block">
          <div class="AboutClients">
            CLIENTS BACKED BY{" "}          
          </div>
          <div class="AboutBanner">
            <img src="./images/thumbnails/backers.png" alt="Clay Gorilla corporate regrets this interruption. Please refresh.">
            </img>
        
          </div>
        </a>

      </div>


      
    </div>
    </div>
  </>
);

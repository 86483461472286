import React, { useEffect } from 'react';

const CalendlyWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="calendly-widget-container">
      <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: '9999', // Ensure the widget is above other elements
      }}
        className="calendly-badge-widget"
        data-url="https://calendly.com/claygorilla-intro/30min" // Replace with your custom Calendly booking link
      >
        <a href="https://calendly.com/claygorilla-intro/30min" target="_blank" rel="noopener noreferrer">
          Don't be shy, say hi!
        </a>
      </div>
    </div>
  );
};

export default CalendlyWidget;


import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { images } from "../images";
import Container from "react-bootstrap/Container";

const WakeUpVote = () => (
  <div className="single">
    <img src={`../images/projects/WakeUpVote/1.webp`} alt="Clay Gorilla" />

    <Container className="Detail" id="DetailContainer">
      <div class="row">
        {/* <div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4"></div> */}

        <div
          class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
          id="title"
        >
          <h2 class="d-flex flex-column justify-content-between .d-block font-weight-bold">
            <div id="ProjectTitle">Wake Up & Vote Campaign</div>
          </h2>
        </div>

        <div
          class="col-12 col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9"
          id="title"
        >
          <h2 class="d-flex flex-column justify-content-between .d-block font-weight-bold">
            <div id="Tagline">
              Desigining thought provoking posters that make people reflect
            </div>
          </h2>
        </div>
      </div>

      <div class="row" id="summaryrow">
        <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="label">The Challenge</div>
            <div class="description">
              Create supporting assets for a statewide Election Commission of
              India campaign to increase voter turnout.
            </div>
          </a>
        </div>

        <div class="col-12 col-xs-1 col-sm-12 col-md-1 col-lg-1 col-xl-1 spacerOutcome"></div>
        {/* spacer */}

        <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="label">The Outcome</div>
            <div class="description">
              A set of two posters with eye–catching graphics circulated across
              the state and internet.
            </div>
          </a>
        </div>
      </div>

      <div class="col-12 col-xs-9 col-sm-9 col-md-9 col-lg-3 col-xl-3 line" />

      <div class="row projectText">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-5 col-xl-5">
          <a class="d-flex flex-column justify-content-between .d-block">
            <p class="body-text d-flex flex-column justify-content-between .d-block">
              Every year, 45 million young citizens become eligible to vote in
              India. However, most campaign posters fail to provide a real call to action.
            </p>
            <p class="body-text d-flex flex-column justify-content-between .d-block">
            As part of a larger drive, a set of two posters was designed to catch the
              attention of the youth for a statewide campaign by the Election
              Commission.
            </p>

          </a>
        </div>
      </div>

      {/* Project pictures start */}

      {/* Second Image with caption */}
      <div class="row ProjectImage">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-2 col-xl-3 flexy">
          {" "}
          {/*saumya's edits*/}
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescription" class="">
              
            </div>
            {/*saumya's edits*/}
            {/* spacer */}
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <img
              src="../images/projects/WakeUpVote/2.webp"
              class=" d-block"
              alt="..."
            />
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescriptionMDbelow" class="">
              
            </div>
            {/* spacer */}
          </a>
        </div>
      </div>

      {/* Third Image with caption */}
      <div class="row ProjectImage">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-2 col-xl-3 flexy">
          {" "}
          {/*saumya's edits*/}
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescription" class="">
              
            </div>
            {/*saumya's edits*/}
            {/* spacer */}
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <img
              src="../images/projects/WakeUpVote/3.gif"
              class=" d-block"
              alt="..."
            />
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescriptionMDbelow" class="">
              
            </div>
            {/* spacer */}
          </a>
        </div>
      </div>
    </Container>
  </div>
);

export default WakeUpVote;

import React from "react";
import ReactDOM from "react-dom";
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

import { BrowserRouter as Router, Switch, Route , useLocation} from "react-router-dom";
import { Gallery } from "./Gallery";
import { About } from "./About";
import { History } from "./History";
import { Contact } from "./Contact";
import { SingleImage } from "./SingleImage";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles.css";
import Container from 'react-bootstrap/Container';

import Test  from './projects/Test';
import Parkinsons from './projects/Parkinsons';
import Braille from './projects/Braille';
import Forus from './projects/Forus';
import Abhilasha from './projects/Abhilasha';
import WakeUpVote from './projects/WakeUpVote';
import Pathways from './projects/Pathways';
import TISDC from './projects/TISDC';
import Xross from './projects/Xross';

import CalendlyWidget from './CalendlyWidget';



import createScrollSnap from 'scroll-snap'
import styled, { createGlobalStyle } from 'styled-components';

// Get Framer Motion https://framer.com/motion
const Wrapper = styled.div`
  scroll-snap-type: y mandatory;
  max-height: 100vh;
  overflow-y: scroll;
`;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => (
  <Router>
    <ScrollToTop />

    <AnimatePresence exitBeforeEnter>
      <Switch>
        <Route exact path="/" component={About} />
      </Switch>
    </AnimatePresence>

    <AnimatePresence exitBeforeEnter>
      <Switch>
        <Route exact path="/" component={Gallery} />
        <Route exact path="/project/1" component={Pathways} />
        <Route exact path="/project/2" component={Parkinsons} />
        <Route exact path="/project/3" component={Forus} />
        <Route exact path="/project/4" component={Xross} />
        <Route exact path="/project/5" component={Braille} />
        <Route exact path="/project/6" component={Abhilasha} />
        <Route exact path="/project/7" component={TISDC} />
        <Route exact path="/project/8" component={WakeUpVote} />

      </Switch>
    </AnimatePresence>

    <AnimatePresence exitBeforeEnter>
      <Switch>
      <Route exact path="/" component={History} />
      </Switch>
    </AnimatePresence>

    <AnimatePresence exitBeforeEnter>
      <Switch>
      <Route exact path="/" component={Contact} />
      </Switch>
    </AnimatePresence>


    <CalendlyWidget />

  </Router>
);


const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

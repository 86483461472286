export const images = [
  {title: "Helping children become resilient by thinking more objectively",
  id: "1", catchphrase: "Pathways"
  },
  {title: "Reimagining accessible clothing one button at a time",
  id: "2", catchphrase: "EasyFit"
  },
  {title: "Bringing early detection of severe eye conditions to the Indian masses",
  id: "3", catchphrase: "Forus Fundal Reflex Screening Camera"
  },
  {title: "Designing an extension box that allows for easy cable management",
  id: "4", catchphrase: "Cross"
  },
  {title: "Providing the visually impaired with equitable access to higher education in mathematics",
  id: "5", catchphrase: "Braille Slate for Mathematics"
  },
  
  {title: "Designing classroom material to engage young students",
  id: "6", catchphrase: "Abhilasha"
  },
  {title: "Designing a discreet fixture to help the elderly get up",
  id: "7", catchphrase: "TISDC Winner: The Light Support"
  },

  {title: "Wake Up & Vote Campaign",
  id: "8", catchphrase: ""
  }
  

];

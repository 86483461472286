import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { images } from "./images";
import Container from "react-bootstrap/Container";
import Marquee, { MarqueeProps, Axis, Align } from "react-easy-marquee";

export const Contact = () => (
  <>
    <div class="supercontainer">
      <Container className="About" id="ContactContainer">
        <div class="row">
          <div style={{'padding-bottom':'1rem'}} class="col-12 col-sm-9 col-md-9 col-lg-8 col-xl-9">
            <a class="d-flex flex-column justify-content-between .d-block">
              <div class="AboutDescription">
                Call us at{" "}<u class="contact-line">
                              
                </u>
                <br />
                 <u class="underline">
                  <a href="tel:+919663804498">+91 96638 04498, </a>
                </u> 
                <br />
                or say hi! at{" "}
                <u class="underline">
                  <a href="mailto:connect@claygorilla.com?subject=I want to work with you to design a ________.&body=Hi. I'm ________. I want to contract Clay Gorilla to work on a project of mine. Here's what I've in mind. When can we talk?">
                    hi@claygorilla.com
                  </a>
                </u>
              </div>
            </a>
          </div>

        
        </div>
      </Container>
      <div class="row" id="edgetoedge">
        <Marquee
          className="marquee"
          duration={40000}
          background=""
          height="4rem"
          width="100%"
          axis="X"
          align="center"
          pauseOnHover={true}
          reverse={true}
        >
          <div class="marquee-scroll">
            <Link to={`/project/1`}>
              {" "}
              Enabling Parkinson's patients to dress themselves.{" "}
            </Link>
          </div>
          <div class="marquee-scroll">◦</div>
          <div class="marquee-scroll">
            <Link to={`/project/6`}>
              {" "}
              A game to make children think objectively.
            </Link>
          </div>
          <div class="marquee-scroll">◦</div>

          <div class="marquee-scroll">
            <Link to={`/project/2`}>
              {" "}
              Helping visually impaired children do mathematics.
            </Link>
          </div>
          <div class="marquee-scroll">◦</div>
        </Marquee>
        <Marquee
          className="marquee2"
          duration={40000}
          background=""
          height="4rem"
          width="100%"
          axis="X"
          align="center"
          pauseOnHover={true}
          reverse={false}
        >
          <div class="marquee-scroll">
            <Link to={`/project/3`}>Low cost, mass eye screenings.</Link>
          </div>
          <div class="marquee-scroll">◦</div>

          <div class="marquee-scroll">
            <Link to={`/project/7`}>
              {" "}
              A tool to help the elderly stand up safely.
            </Link>
          </div>
          <div class="marquee-scroll">◦</div>

          <div class="marquee-scroll">
            <Link to={`/project/5`}> A truly provocative campaign poster.</Link>
          </div>
          <div class="marquee-scroll">◦</div>
        </Marquee>
      </div>
    </div>
  </>
);

import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { images } from "../images";
import Container from "react-bootstrap/Container";

const Xross = () => (
  <div className="single">
    <img src={`../images/projects/Xross/1.webp`} alt="Clay Gorilla" />

    <Container className="Detail" id="DetailContainer">
      <div class="row">
        {/* <div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4"></div> */}

        <div
          class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
          id="title"
        >
          <h2 class="d-flex flex-column justify-content-between .d-block font-weight-bold">
            <div id="ProjectTitle">Xross</div>
          </h2>
        </div>

        <div
          class="col-12 col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9"
          id="title"
        >
          <h2 class="d-flex flex-column justify-content-between .d-block font-weight-bold">
            <div id="Tagline">
              Designing an extension box that allows for easy cable management
            </div>
          </h2>
        </div>
      </div>

      <div class="row" id="summaryrow">
        <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="label">The Challenge</div>
            <div class="description">
              Design an extension box that allows for four points to be used
              simultaneously in any orientation.
            </div>
          </a>
        </div>

        <div class="col-12 col-xs-1 col-sm-12 col-md-1 col-lg-1 col-xl-1 spacerOutcome"></div>
        {/* spacer */}

        <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="label">The Outcome</div>
            <div class="description">
              An extension box that makes cable management easy by sticking to
              any surface and houses an inbuilt spool.
            </div>
          </a>
        </div>
      </div>

      <div class="col-12 col-xs-9 col-sm-9 col-md-9 col-lg-3 col-xl-3 line" />

      <div class="row projectText">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-5 col-xl-5">
          <a class="d-flex flex-column justify-content-between .d-block">
            <p class="body-text d-flex flex-column justify-content-between .d-block">
              Cable management has always been an issue in the modern workspace. While most solutions revolve around
              hiding the mess, Xross brings a way for you to anchor the box onto any surface.
            </p>
            <p class="body-text d-flex flex-column justify-content-between .d-block">
              Xross's four–way plug system allows the excess cable to be wound around
              a spool while adhering to any surface, in any orientation. The palette, consisting of a speckled white and youthful accent colour makes it fit right in with modern homes while adding a dash of
              colour to any creative table.
            </p>
          </a>
        </div>
      </div>

      {/* Project pictures start */}

      {/* First Image with caption */}
      <div class="row ProjectImage">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-2 col-xl-3 flexy">
          {" "}
          {/*saumya's edits*/}
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescription" class="">
              Fast and easy assembly.
            </div>
            {/*saumya's edits*/}
            {/* spacer */}
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <img
              src="../images/projects/Xross/2.webp"
              class=" d-block"
              alt="..."
            />
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescriptionMDbelow" class="">
              Fast and easy assembly.
            </div>
            {/* spacer */}
          </a>
        </div>
      </div>
    </Container>
  </div>
);

export default Xross;

import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { images } from "./images";
import Container from "react-bootstrap/Container";

export const History = () => (
  <>
  <div className="About min-vh-100" id="HistoryContainer">
    <div class="row">
      <div class="col-12 col-sm-9 col-md-9 col-lg-8 col-xl-9">
        <a class="d-flex flex-column justify-content-between .d-block">
          <div class="AboutDescription">
            The primates <b class="super">3, </b>
            <b class="super">4</b> behind Clay Gorilla are graduates from the
            Department of Design, IIT Guwahati and have worked with people from
            Samsung, Icarus, Forus Health and Pluralsight.<b class="super">5</b>{" "}
            <p id="cheeky"> (and maybe you?)</p>
          </div>
        </a>
      </div>

      <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
        <div class="row">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="row spacer" id="profile-img-spacer"></div>
          </a>
        </div>

        <div class="row">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="footnote-alt">
              <img
                id="profile-img"
                src="./images/profiles/saumya.webp"
                alt="Clay Gorilla corporate regrets this interruption. Please refresh."
              ></img>{" "}
              <br />
              3. Saumya Mehta is an experience designer who solves problems
              through design. She believes that design is rethinking from
              zero—innovating from ground up to build products that are
              exceptional. Her current interest areas include designing with
              psychology and neuroscience to develop systems that support the
              human brain.{" "} <sup>6</sup>
            </div>
          </a>

          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="footnote-alt">
              <img
                id="profile-img"
                src="./images/profiles/rithwik.webp"
                alt="Clay Gorilla corporate regrets this interruption. Please refresh."
              ></img>{" "}
              <br />
              4. Rithwik Jayasimha is a hacker turned designer who (also) solves
              problems through design (and wishes he got to go first). He
              believes in looking at the bigger picture and ensuring that his
              designs fit their ecosystems for the highest impact. He is easily
              excited by new tech, breaking systems and the 'aha' that makes
              people love things.
            </div>
          </a>
        </div>

        <div class="row">
          <a class="d-flex flex-column justify-content-between .d-block">
            {/* <div class="row spacer"></div> */}
            <div id="footnoteexception" class="footnote">
              5. This means you can trust them.
            </div>
            <div id="footnoteexception" class="footnote">
              6. Did you yawn?
            </div>

          </a>
        </div>
      </div>
    </div>
    </div>
  </>
);

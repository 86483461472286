import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { images } from "../images";
import Container from "react-bootstrap/Container";

const Pathways = () => (
  <div className="single">
    <img src={`../images/projects/Pathways/1.webp`} alt="Clay Gorilla" />

    <Container className="Detail" id="DetailContainer">
      <div class="row">
        {/* <div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4"></div> */}

        <div
          class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
          id="title"
        >
          <h2 class="d-flex flex-column justify-content-between .d-block font-weight-bold">
            <div id="ProjectTitle">Pathways</div>
          </h2>
        </div>

        <div
          class="col-12 col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9"
          id="title"
        >
          <h2 class="d-flex flex-column justify-content-between .d-block font-weight-bold">
            <div id="Tagline">
              Helping children become resilient by thinking more objectively
            </div>
          </h2>
        </div>
      </div>

      <div class="row" id="summaryrow">
        <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="label">The Challenge</div>
            <div class="description">
              Equip children with methods to get over negative thinking
              patterns.
            </div>
          </a>
        </div>

        <div class="col-12 col-xs-1 col-sm-12 col-md-1 col-lg-1 col-xl-1 spacerOutcome"></div>
        {/* spacer */}

        <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="label">The Outcome</div>
            <div class="description">
              A (super) fun card game that leverages story building to rewire
              children to evaluate real world situations more objectively and
              not jump to negative conclusions.
            </div>
          </a>
        </div>
      </div>

      <div class="col-12 col-xs-9 col-sm-9 col-md-9 col-lg-3 col-xl-3 line" />

      <div class="row projectText">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-5 col-xl-5">
          <a class="d-flex flex-column justify-content-between .d-block">
            <p class="body-text d-flex flex-column justify-content-between .d-block">
              With the popularity of the internet today, children are constantly
              comparing themselves to everyone, everywhere, all at once. This
              leads to increasing feeling of insecurity and doubt about their
              competence which makes them more likely to fall into negative
              thinking patterns.
            </p>
            <p class="body-text d-flex flex-column justify-content-between .d-block">
              Pathways was researched and designed alongside leading child
              psychologists from across the country. It relies on story building
              to slowly—but surely—train young brains to look at situations more
              objectively. The simple graphics and layout make it easy to follow
              and feel like any other card game that you'd enjoy on a lazy
              weekend.
            </p>
            <p class="body-text d-flex flex-column justify-content-between .d-block">
              The prompts have been refined over a series of playtests with
              children of different age groups and backgrounds to make them as
              relatable and fun as possible. The end result is a simple, fun and
              engaging game that positively impacts a child's development.
            </p>
          </a>
        </div>
      </div>

      {/* Project pictures start */}

      {/* First Image with caption */}
      <div class="row ProjectImage">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-2 col-xl-3 flexy">
          {" "}
          {/*saumya's edits*/}
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescription" class="">
              The three decks of Pathways—the prompt deck, the story building deck and the power cards.
            </div>
            {/*saumya's edits*/}
            {/* spacer */}
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <img
              src="../images/projects/Pathways/2.webp"
              class=" d-block"
              alt="..."
            />
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescriptionMDbelow" class="">
            The three decks of Pathways—the prompt deck, the story building deck and the power cards.
            </div>
            {/* spacer */}
          </a>
        </div>
      </div>

      {/* Second Image with caption */}
      <div class="row ProjectImage">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-2 col-xl-3 flexy">
          {" "}
          {/*saumya's edits*/}
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescription" class="">
              
            </div>
            {/*saumya's edits*/}
            {/* spacer */}
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <img
              src="../images/projects/Pathways/3.webp"
              class=" d-block"
              alt="..."
            />
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescriptionMDbelow" class="">
              
            </div>
            {/* spacer */}
          </a>
        </div>
      </div>

      {/* Second Image with caption */}
      <div class="row ProjectImage">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-2 col-xl-3 flexy">
          {" "}
          {/*saumya's edits*/}
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescription" class="">
             

             
            </div>
            {/*saumya's edits*/}
            {/* spacer */}
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <img
              src="../images/projects/Pathways/4.webp"
              class=" d-block"
              alt="..."
            />
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescriptionMDbelow" class="">


            </div>
            {/* spacer */}
          </a>
        </div>
      </div>

      {/* Second Image with caption */}
      <div class="row ProjectImage">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-2 col-xl-3 flexy">
          {" "}
          {/*saumya's edits*/}
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescription" class="">
              

              
            </div>
            {/*saumya's edits*/}
            {/* spacer */}
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <img
              src="../images/projects/Pathways/5.webp"
              class=" d-block"
              alt="..."
            />
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescriptionMDbelow" class="">
              

              
            </div>
            {/* spacer */}
          </a>
        </div>
      </div>
    </Container>
  </div>
);

export default Pathways;

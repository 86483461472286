import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { images } from "./images";
import Container from "react-bootstrap/Container";

const frameVariants = {
  hover: { scale: 0.95 },
};

const imageVariants = {
  hover: { scale: 1.1 },
};

const Thumbnail = ({ key, id, i, title, catchphrase }) => (
  <div class="row galleryRow">
    <div class="col-12 col-sm-9 col-md-12 col-lg-8 col-xl-9">
      <a class="d-flex flex-column justify-content-between .d-block">
        <motion.div className="thumbnail">
          <motion.div
            className="frame"
            whileHover="hover"
            variants={frameVariants}
           
          >
            <Link to={`/project/${i}`}>
              <motion.img
                src={`./images/thumbnails/${id}.webp`}
                alt="Clay Gorilla corporate regrets this interruption. Please refresh."
             
               
              />
            </Link>
          </motion.div>
        </motion.div>
      </a>
    </div>
    <div class="col-12 col-sm-3 col-md-9 col-lg-4 col-xl-3 flexy">
      <a class="d-flex flex-column justify-content-between .d-block ">
        <motion.div className="catchphrasetext">{catchphrase}</motion.div>
        <motion.div className="thumbnailtext">{title}</motion.div>
      </a>
    </div>
  </div>
);

export const Gallery = () => (
  <>
    <div className="gallery">
      <div
        className="thumbnails"
      >
        {images.map((input) => (
          <Thumbnail
            key={input.id}
            id={input.id}
            i={input.id}
            catchphrase={input.catchphrase}
            title={input.title}
          />
        ))}
      </div>
    </div>
  </>
);

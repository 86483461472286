import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion/dist/framer-motion';
import { images } from "./images";
import Container from 'react-bootstrap/Container';

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
};

const imageVariants = {
  exit: { y: "50%", opacity: 0, transition },
  enter: {
    y: "0%",
    opacity: 1,
    transition
  }
};

const backVariants = {  
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 1, ...transition } }
};

export const SingleImage = ({ match }) => (
  <motion.div className="single" initial="exit" animate="enter" exit="exit">
    <motion.img
      variants={imageVariants}
      src={`/images/${
        images[parseInt(match.params.id, 10)]
      }.png`}
      alt="Clay Gorilla"
    />
    <motion.div className="back" variants={backVariants}>
      <Link to="/">← Back</Link>
    </motion.div>

    <Container className="Detail" id="DetailContainer">

    <div class="row">
			<div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				
				</a>
			</div>

			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">Designing Clothing Adaptations for Patients with Parkinson's</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-2 col-xl-2">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Timestamp</div>
					<div class="description">December 2021—May 2022</div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-1 col-xl-1">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Type</div>
					<div class="description">Product</div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 mr-0 col-12 col-md-4 col-xl-4 col-s-2 pr-5">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Description</div>
					<div class="description">Frugal adaptations designed to allow patients with Parkinson's to adapt their existing clothing and clothe themselves with dignity.</div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-2 col-xl-2">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Project Presentation</div>
					<div class="description"><a href="https://www.figma.com/proto/EAY9I5nPg4j68Aj5lslqb8/BTP-Presentations?node-id=700%3A21&scaling=scale-down&page-id=700%3A20"><u>View</u></a></div>
				</a>
			</div>
		</div>
    </Container>
  </motion.div>

  
);

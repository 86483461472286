import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { images } from "../images";
import Container from "react-bootstrap/Container";

const Forus = () => (
  <div className="single">
    <img src={`../images/projects/Forus/1.webp`} alt="Clay Gorilla" />

    <Container className="Detail" id="DetailContainer">
      <div class="row">
        {/* <div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4"></div> */}

        <div
          class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
          id="title"
        >
          <h2 class="d-flex flex-column justify-content-between .d-block font-weight-bold">
            <div id="ProjectTitle">Fundal Reflex Screening Camera</div>
          </h2>
        </div>

        <div
          class="col-12 col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9"
          id="title"
        >
          <h2 class="d-flex flex-column justify-content-between .d-block font-weight-bold">
            <div id="Tagline">
              Bringing early detection of severe eye conditions to the Indian
              masses
            </div>
          </h2>
        </div>
      </div>

      <div class="row" id="summaryrow">
        <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="label">The Challenge</div>
            <div class="description">
              Rethink fundus imaging equipment to perform quick screenings for
              early detection of eye conditions amongst patients and young
              children, at scale.
            </div>
          </a>
        </div>

        <div class="col-12 col-xs-1 col-sm-12 col-md-1 col-lg-1 col-xl-1 spacerOutcome"></div>
        {/* spacer */}

        <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="label">The Outcome</div>
            <div class="description">
              A portable and affordable device with excellent ergonomics and
              external data processing for mass screenings. Can be used handheld
              for young children while maintaining a non threatening aesthetic.
            </div>
          </a>
        </div>
      </div>

      <div class="col-12 col-xs-9 col-sm-9 col-md-9 col-lg-3 col-xl-3 line" />

      <div class="row projectText">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-5 col-xl-5">
          <a class="d-flex flex-column justify-content-between .d-block">
            <p class="body-text d-flex flex-column justify-content-between .d-block">
              For a large number of Indians from the lower socioeconomic classes, an eye checkup is an appointment that is never made, unless the patient is in extreme distress. Cataracts, Glaucoma and Retinoblastomas are all conditions that have better prognoses when detected early, especially in young children.

            </p>

            <p class="body-text d-flex flex-column justify-content-between .d-block">
              Forus' screening camera was developed to leverage the red eye reflex that is a result of bright light reflecting off the retinal screen when the pupils are in a dialated state. The captured images are evaluated at scale in the cloud to detect issues early on. The design of the camera was tailored to make it feel non–threatening and allow it to be used in any orientation, with a soft trigger button being implemented to help reduce blur when the trigger is depressed. 
            </p>

          </a>
        </div>
      </div>

      {/* Project pictures start */}

      {/* First Image with caption */}
      <div class="row ProjectImage">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-2 col-xl-3 flexy">
          {" "}
          {/*saumya's edits*/}
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescription" class="">
A soft trigger along with a delay was used to eliminate shutter shake.          </div>
            {/*saumya's edits*/}
            {/* spacer */}
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <img
              src="../images/projects/Forus/1.webp"
              class=" d-block"
              alt="..."
            />
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescriptionMDbelow" class="">
             A soft trigger along with a delay was used to eliminate shutter shake.
            </div>
            {/* spacer */}
          </a>
        </div>
      </div>
    </Container>
  </div>
);

export default Forus;

import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { images } from "../images";
import Container from "react-bootstrap/Container";

const TISDC = () => (
  <div className="single">
    <img src={`../images/projects/TISDC/1.webp`} alt="Clay Gorilla" />

    <Container className="Detail" id="DetailContainer">
      <div class="row">
        {/* <div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4"></div> */}

        <div
          class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
          id="title"
        >
          <h2 class="d-flex flex-column justify-content-between .d-block font-weight-bold">
            <div id="ProjectTitle">The Light Support</div>
          </h2>
        </div>

        <div
          class="col-12 col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9"
          id="title"
        >
          <h2 class="d-flex flex-column justify-content-between .d-block font-weight-bold">
            <div id="Tagline">
              Designing a discreet fixture to help the elderly get up from a
              seated position
            </div>
          </h2>
        </div>
      </div>

      <div class="row" id="summaryrow">
        <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="label">The Challenge</div>
            <div class="description">
              Create a physical mechanism that allows the elderly to stand up at
              their own pace.
            </div>
          </a>
        </div>

        <div class="col-12 col-xs-1 col-sm-12 col-md-1 col-lg-1 col-xl-1 spacerOutcome"></div>
        {/* spacer */}

        <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <a class="d-flex flex-column justify-content-between .d-block">
            <div class="label">The Outcome</div>
            <div class="description">
              Unobtrusive, socially acceptable solution disguised as a light
              fixture to be used with public benches or home furniture.
            </div>
          </a>
        </div>
      </div>

      <div class="col-12 col-xs-9 col-sm-9 col-md-9 col-lg-3 col-xl-3 line" />

      <div class="row projectText">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-5 col-xl-5">
          <a class="d-flex flex-column justify-content-between .d-block">
            <p class="body-text d-flex flex-column justify-content-between .d-block">
              Falls are the second leading cause of unintentional injury deaths
              worldwide with older people being at the highest risk. In India,
              the elderly avoid using walking aids like sticks and walkers, even
              when required, as they do not want to feel dependent on a crutch
              to move around. For a large majority, the walking stick would
              prove to be useful when trying to get up from a chair or bed.
            </p>
            <p class="body-text d-flex flex-column justify-content-between .d-block">
              The Light Support provides a stable support when getting up from a
              seated position. It allows users to stand up at their own pace to
              ensure trust in the solution. The form and finish hide it away in
              plain sight as it stands, posing as a floor lamp.
            </p>

            <p class="body-text d-flex flex-column justify-content-between .d-block">
              The design was a finalist in the Taiwan International
              Student Design Competition in 2019.
            </p>
          </a>
        </div>
      </div>

      {/* Project pictures start */}

      {/* First Image with caption */}
      <div class="row ProjectImage">
        <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-2 col-xl-3 flexy">
          {" "}
          {/*saumya's edits*/}
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescription" class="">
              The solution can be bolted to the ground next to public benches or
              home furniture.
            </div>
            {/*saumya's edits*/}
            {/* spacer */}
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <img
              src="../images/projects/TISDC/2.webp"
              class=" d-block"
              alt="..."
            />
          </a>
        </div>

        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <a class="d-flex flex-column justify-content-between  .d-block">
            <div id="ImageDescriptionMDbelow" class="">
              The solution can be bolted to the ground next to public benches or
              home furniture.
            </div>
            {/* spacer */}
          </a>
        </div>
      </div>
    </Container>
  </div>
);

export default TISDC;
